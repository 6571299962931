import React, { ReactNode } from "react";
import classnames from "classnames";

// Styles
import Styles from "./NoRecordFound.module.scss";

// Components
import AppBtn from "@vahak/core/dist/components/app-btn/AppBtn";

// Interface
interface NoRecordFoundProps {
    icon?: ReactNode;
    message?: string;
    ctaText?: string;
    onClick?: () => void;
    containerClassname?: string;
    iconContainerClassname?: string;
    textContainerClassname?: string;
    btnContainerClassname?: string;
    btnClassname?: string;
    ctaSlot?: React.ReactNode;
}
const NoRecordFound = ({
    icon,
    message,
    ctaText,
    onClick,
    containerClassname,
    iconContainerClassname,
    textContainerClassname,
    btnContainerClassname,
    btnClassname,
    ctaSlot
}: NoRecordFoundProps) => {
    return (
        <div className={classnames(Styles.mainContainer, containerClassname)}>
            <div className={classnames(Styles.iconContainer, iconContainerClassname)}>{icon}</div>
            <div className={classnames(Styles.textContainer, textContainerClassname)}>{message}</div>
            {ctaSlot
                ? ctaSlot
                : onClick &&
                  ctaText && (
                      <div className={classnames(Styles.btnContainer, btnContainerClassname)}>
                          <AppBtn className={classnames(Styles.actionBtn, btnClassname)} onClick={onClick}>
                              {ctaText}
                          </AppBtn>
                      </div>
                  )}
        </div>
    );
};

export default NoRecordFound;
